import { useEffect, useState } from "react";
import { useRouter } from "next/router";

const usePreservedSearchParams = () => {
  const router = useRouter();
  const [persistedParams, setPersistedParams] = useState({});

  useEffect(() => {
    if (!router.isReady) return;

    const extractDynamicKeys = () => {
      return router.pathname
        .split("/")
        .filter((part) => part.startsWith("[") && part.endsWith("]"))
        .map((key) => key.slice(1, -1));
    };

    const dynamicKeys = extractDynamicKeys();

    const currentParams = { ...router.query };
    dynamicKeys.forEach((key) => {
      delete currentParams[key];
    });

    if (Object.keys(persistedParams).length === 0) {
      setPersistedParams(currentParams);
    }
  }, [router.isReady, router.pathname, router.query]);

  useEffect(() => {
    if (!router.isReady || Object.keys(persistedParams).length === 0) return;

    const updatedQuery = {
      ...router.query,
      ...persistedParams,
    };

    if (JSON.stringify(router.query) !== JSON.stringify(updatedQuery)) {
      router.push(
        {
          pathname: router.pathname,
          query: updatedQuery,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router, persistedParams]);

  return persistedParams;
};

export default usePreservedSearchParams;
